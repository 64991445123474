.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    position: fixed;
    height: 100%;
    min-width: 250px;
    max-width: 250px;
    background: #2d3748;
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: $first;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid $first;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: $second;
    background: #fff;
}

#sidebar a[data-toggle="collapse"] {
    position: relative;
}

#sidebar ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: $third;
}

#sidebar ul.CTAs {
    padding: 20px;
}

#sidebar ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

#sidebar a.download {
    background: #fff;
    color: $second;
}

#sidebar a.article,
#sidebar a.article:hover {
    background: $third !important;
    color: #fff !important;
}

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
    #content {
        margin-left: 0;
    }
    .content-no-side-nav {
        margin-left: 250px !important;
    }
}

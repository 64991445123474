a:hover {
    color: $first-hover !important;
    opacity: .7;
}

.active {
    color: $first !important;
}

.text-first{
    color: $first !important;
}

.text-second {
    color: $text-second !important;
}

.text-third {
    color: $third !important;
}

.bg-first,
.btn-first {
    background: $first;
    color: #fff;
}

.btn-outline-first {
    border: 1px solid $first;
    color: #fff;
    transition: .25s ease-in-out;
}

.btn-first:hover,
.btn-outline-first:hover {
    background: $first-hover;
    color: #fff !important;
}

.bg-second,
.btn-second {
    background: $second;
    color: #fff;
}

.bg-third {
    background: $third;
    color: #fff;
}

.bg-slate {
    background: $slate;
    color: #fff;
}

.btn-glass,
.bg-glass {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border: 1px solid $first;
    color: $first;
}

.bg-footer {
    background: $bg-footer;
    color: #fff;
}
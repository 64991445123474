//// Body
//$first-hover: rgb(216, 186, 145);
//$first: rgba(209, 172, 53, 0.98);
//$second: #565759;
//$third: #2C3E50;
//$slate: #2d2c2a;
//$glass: rgb(255 255 255 / 16%);
//$bg-color: #37383E;

$first-hover: var(--primary-hover);
$first: var(--primary);
$second: var(--secondary);
$text-second: var(--text-secondary);
$third: var(--primary-color);
$slate: var(--text-primary);
$bg-color: var(--base);
$bg-footer: var(--footer);

// Typography
$font-size-base: 1.2rem;
$line-height-base: 1.6;

#back-end {

    font-size: 1rem;

    h4 {
        font-size: 1.4rem;
    }

    ul li a {
        font-size: 1em;
    }

    .btn {
        font-size: 1rem;
    }

    a.btn-primary {
        color: #fff !important;
    }
    a.btn-primary:hover {
        color: #fff !important;
    }

    .input-group-text,
    .form-control {
        font-size: 1.1rem;
    }

    a:hover {
        color: #0d6efd !important;
    }

    #sidebar a {
        color: #eee !important;
    }

    #sidebar ul li a:hover {
        background: #91a3e3;
    }

    .auth-nav {
        .dropdown-toggle {
            color: #000 !important;
        }
        a {
            color: #000 !important;
        }
    }
}

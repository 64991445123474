//Frontend Navbar
#contact-bar {

}

.frontend-navbar {
//    //margin-top: 81px;
//
//    .navbar-toggler {
//        box-shadow: none !important;
//        border: none !important;
//    }
//
//    .navbar-brand {
//        margin: -20px 0 -20px 0;
//    }

    .main-nav {
        color: #fff;
        //box-shadow: 0px 15px 15px 0px rgba(0,0,0,0.1);
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
    }

    .main-nav-item {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        flex-grow: 1;
        text-align: center;
    }

    .main-nav-link {
        display: block;
        padding: 15px;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        transition: background-color 0.3s;
    }

    .main-nav-link:hover {
        background: $first;
        color: #fff;
    }
    .main-nav-link:focus {
        color: $first;
        background: #fff;
    }
}

//Auth Navbar
.auth-nav {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

#content {
    margin-left: 250px;
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

.content-no-side-nav {
    margin-left: 0 !important;
}

#page-hero {
    padding-bottom: 12%;
    padding-top: 12%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 100% !important;

    hr {
        border-top: 10px solid $second;
        opacity: 1;
        margin: 25px 25%;
    }
}

#img {
    width: 100%;
    height: 100%;
    object-position: 0% 0%;
    -o-object-fit: cover;
    object-fit: cover;
}

#cover {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

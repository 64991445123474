#hero {
background-size: cover !important;
    padding-bottom: 150px;
    padding-top: 10%;
    background-position: left !important;
    background-repeat: no-repeat !important;
}

.nav-wave-mobile {
    position: absolute;
    top: 205px;
    z-index: 0;
}

.nav-wave-desktop {
    position: absolute;
    top: 160px;
    z-index: 0;
}

#home-content .overlap-cards {
    margin-top: -250px;
}


.service-card {
    background-size: cover !important;
    transition: transform .2s;
    height: 250px;
}
.service-card:hover {
    transform: scale(1.03);
}


.fade-up{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}
.fade-left{
    //position: relative;
    transform: translateX(-150px);
    opacity: 0;
    transition: 1s all ease;
}
.fade-right{
    //position: relative;
    transform: translateX(150px);
    opacity: 0;
    transition: 1s all ease;
}
.reveal.animated,
.onload.animated {
    transform: translateX(0);
    opacity: 1;
}

@media (max-width: 992px) {
    .fade-left{
        transform: translateY(150px);
    }
    .fade-right{
        transform: translateY(150px);
    }
}

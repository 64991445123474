body {
    overflow-x: hidden;
}

.cursor{
    cursor: default;
}

.pointer{
    cursor: pointer;
}

#front-end {
    font-family: 'Lato', sans-serif;
    background: #fff !important;

    .card {
        border: none !important;
        border-radius: 0;
        background: #fff;
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        color: $second;
    }

    .btn {
        border-radius: 0 !important;
    }

    a .card {
        transition: 0.3s;
    }

    a .card:hover {
        margin-top: -10px;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    }

    .page-content {
        position: relative;
        z-index: 1;
    }

    #page-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(38,38,38,.5);
        z-index: 2;
        display: none;
    }
}

h1, .h1 {
    font-size: calc(4rem + 0.1vw);
}

h5, .h5 {
    font-size: 1.2rem;
}

a{
    text-decoration: none;
    transition: .25s ease-in-out;
}

.mt-m200 {
    margin-top: -200px !important;
}

.mb-m200 {
    margin-bottom: -200px !important;
}

.before-shape {
    margin-bottom: -1px;
}

.after-shape {
    margin-top: -1px;
}

section {
    padding-top: 125px;
    padding-bottom: 125px;
}

.full-width {
    width: 100%;
}

.border-none {
    border: none !important;
    border-radius: 0;
}

.footer-card {
    margin-bottom: -250px !important;
}

#footer {
    //padding-top: 110px;
}

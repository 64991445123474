// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Global
@import 'global';
@import 'colors';
@import 'nav';
@import 'sidebar';
@import 'animation';

//Pages
@import 'home';
@import 'page';

//Auth
@import 'auth';
